import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/home/index";
import Report from "./components/report/index";
import Video from "./components/video/index";
import GlobalStyle from "./styles/GlobalStye";
import CouponLayout from "./layouts/CouponLayout";
import SuccessCoupon from "./components/coupon/SuccessCoupon";

function App() {
  return (
    <>
      <GlobalStyle />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/coupon" element={<CouponLayout />} />
          <Route path="/coupon/login" element={<SuccessCoupon />} />
          <Route path="/report" element={<Report />} />
          <Route path="/video" element={<Video />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
