import styled from "@emotion/styled";
import { ReportData } from "../../types/report";

import { ReactComponent as RECSVG } from "../../assets/rec.svg";

const DATA = {
  low: [
    {
      titleBold:
        "주의력이 낮은 어린이들은 외부 자극에 의해 쉽게 산만해지고, 종종 상대방의 말에 집중하지 못하는 모습을 보입니다.",
      title:
        "주의를 유지하는 시간이 짧아서 과제의 완성도가 떨어지게 되고 아는 것도 실수하게 되는 등 학습에서 가진 능력을 발휘하기 어려울 수 있습니다. 또, 주변의 사물들을 비롯한 위험한 상황에 주의를 기울이지 않아 간혹 다치는 경우가 있고, 소지품을 잘 간수하지 못하고 쉽게 잃어버리기도 합니다.",
      bold: "일상생활과 학업 활동 전반에 많은 영향",
      tail: "을 미치기 때문에 주의력 훈련에 도움이 되는 활동을 꾸준히 수행하여 향상하는 것이 중요합니다.",
    },
  ],
  middle: [
    {
      titleBold:
        "주의력이 보통 수준인 어린이들은 과제의 핵심이나 중요한 목표에 집중할 수 있습니다.",
      title:
        "외부 자극에 쉽게 방해받지 않고, 책을 읽거나 수업을 들을 때 정보를 대체로 잘 습득할 수 있으며, 소지품을 잘 간수하고 일정을 스스로 관리할 수 있습니다. 간혹 상대방의 말에 집중하지 못하거나 아는 것도 실수하게 되는 경우가 발생할 수 있으나, 대체로 일상생활에서 주의력과 관련된 큰 문제를 보이지 않을 수 있습니다.",
      bold: "일상생활과 학업 활동 전반에 많은 영향",
      tail: "을 미치기 때문에 꾸준히 훈련하는 것이 중요합니다.",
    },
  ],
  high: [
    {
      titleBold:
        "주의력이 높은 어린이들은 과제의 핵심이나 중요한 목표에 집중할 수 있는 능력이 뛰어납니다.",
      title:
        "외부 자극에 쉽게 방해받지 않고, 책을 읽거나 수업을 들을 때 필요한 정보를 정확하게 습득할 수 있어서 학습을 효율적으로 수행할 수 있습니다. 또한, 소지품을 잘 간수하고 일정을 잘 관리할 수 있으며, 또래관계에서 사회적 단서를 잘 알아챌 가능성이 높습니다.",
      bold: "일상생활과 학업 활동 전반에 많은 영향",
      tail: "을 미치므로 계속해서 잘 발달할 수 있도록 꾸준히 훈련하는 것이 중요합니다.",
    },
  ],
};

interface Props {
  data?: ReportData;
}

export default function Page6({ data }: Props) {
  const taskType =
    data?.inattScoreEv === "높은"
      ? "high"
      : data?.inattScoreEv === "보통"
      ? "middle"
      : "low";

  return (
    <Body>
      <Wrapper>
        <Title>주의력</Title>
        <TopContainer>
          <h1>
            주의력이란? <span>#집중 #학습</span>
          </h1>
          <div>
            주의력은 특정한 자극, 사건, 활동 등에{" "}
            <span>집중하여 관심을 기울이는 능력</span>으로, 특정 자극에
            선택적으로 주의를 집중하고 다른 자극을 억제하며 주의를 유지하는
            정신과정을 의미합니다. 주의력은 아래와 같이 세분화 할 수 있으며 뇌의
            전체적인 정보처리과정을 가능하게 하는 요소 중의 하나로,{" "}
            <span>학습과 관련</span>됩니다.
          </div>
          <div>
            <ol>
              <li>
                시각정보나 청각정보에 관심을 기울이는, 초점주의력(Focused
                attention)
              </li>
              <li>
                특정 과제에 집중을 유지하는, 지속적 주의력(Sustained attention)
              </li>
              <li>
                방해물에 영향을 받지 않고 필요한 자극에만 주의를 기울이는,
                선택적 주의력(Selective attention)
              </li>
              <li>
                여러 과제에 번갈아가며 집중하는, 전환 주의력(Shifted attention)
              </li>
              <li>
                여러 개의 과제에 동시에 집중을 유지하는, 동시 주의력(Divided
                attention)
              </li>
            </ol>
          </div>
          <RecSvg />
        </TopContainer>
        <MiddleContainer
          color={
            data?.inattScoreEv === "높은"
              ? "#3689DC"
              : data?.inattScoreEv === "보통"
              ? "#63AE18"
              : "#DE5C82"
          }
          bdColor={
            data?.inattScoreEv === "높은"
              ? "#79BCFF"
              : data?.inattScoreEv === "보통"
              ? "#87CA43"
              : "#F88DAD"
          }
        >
          <h1>주의력 관련 인지 측정 결과</h1>
          <h2>
            우리 아이의 주의력은{" "}
            <span>
              {data?.inattScoreEv === "높은"
                ? "높은 수준"
                : data?.inattScoreEv === "보통"
                ? "보통 수준"
                : "낮은 수준"}
              <h2>으로 추정됩니다.</h2>
            </span>
          </h2>
        </MiddleContainer>
        {DATA[taskType].map((item) => (
          <MiddleTextContainer key={item.title}>
            <h1>
              <span>{item.titleBold} </span>
              {item.title}
            </h1>
            <h2>
              주의력은 <span>{item.bold}</span>
              {item.tail}
            </h2>
          </MiddleTextContainer>
        ))}
      </Wrapper>
      <Footer>
        <h1>
          <img src="/images/report/!.png" alt="" />
          재미있게 즐기는 훈련 방법을 통해 주의력을 키워보세요.
        </h1>
        <div>
          <div>
            <h2>다른 그림 찾기, 숨은 그림 찾기</h2>
            <p>
              두 그림을 보고 서로 다른 부분을 찾거나 전체 그림 속에서 감추어진
              특정 그림을 찾는 놀이를 통해 시각적 주의력을 훈련할 수 있습니다.
            </p>
          </div>
          <div>
            <h2>코코코 게임</h2>
            <p>
              상대방과 마주 보고 상대방의 행동이 아닌 말을 따라 행동하는 게임을
              함으로써 청각적 주의력을 훈련할 수 있습니다.
            </p>
          </div>
        </div>
      </Footer>
    </Body>
  );
}

const Body = styled.div`
  border-bottom: 8px solid #ededed;
`;

const Wrapper = styled.div`
  position: relative;
  width: 796px;
  margin: 0 auto;
  @media (max-width: 979px) {
    width: 318px;
  }
`;

const Title = styled.div`
  position: absolute;
  top: -23.5px;
  left: 338px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 117px;
  height: 47px;
  background-color: #131313;
  border-radius: 55px;

  color: #fff;
  font-family: Cafe24 Ssurround OTF;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  z-index: 2;

  @media (max-width: 979px) {
    left: 107px;
  }
`;

const TopContainer = styled.div`
  position: relative;
  width: 744px;
  height: 287px;
  padding: 24px;
  margin-top: 143px;
  border: 1px solid #e8e8e8;
  background: #fff;

  color: #000;
  text-align: justify;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 19.2px */

  & > h1 {
    margin-bottom: 8px;
    color: #000;
    font-family: Cafe24 Ssurround OTF;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    & > span {
      color: #0084ff;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }

  & > div {
    :nth-of-type(1) {
      margin-bottom: 16px;
    }

    :nth-of-type(2) {
      padding-left: 24px;
    }

    & > span {
      color: #000;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
    }
  }

  ol {
    list-style-type: decimal;
  }

  @media (max-width: 979px) {
    width: 318px;
    height: 740px;
    padding: 0;
    margin-top: 103px;
    color: #000;
    font-family: Pretendard;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */

    & > h1 {
      margin-top: 38px;
      margin-left: 16px;
      & > span {
        display: block;
        margin-top: 8px;
        margin-bottom: 16px;
      }
    }

    & > div {
      margin-left: 16px;
      font-size: 17px;
      :nth-of-type(1) {
        width: 286px;
      }

      :nth-of-type(2) {
        padding: 0 20px;
      }
    }
    li {
      margin-bottom: 8px;
    }
  }
`;

const RecSvg = styled(RECSVG)`
  position: absolute;
  bottom: -1px;
  right: -1px;
`;

const MiddleContainer = styled.div<{ bdColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 792px;
  height: 118px;
  border-radius: 10px;
  border: 3px solid ${(props) => props.bdColor};
  margin-top: 24px;
  margin-bottom: 24px;
  color: #000;
  font-family: Cafe24 Ssurround OTF;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  span {
    color: ${(props) => props.color};
  }

  & > h1 {
    margin-bottom: 8px;
  }

  & > h2 {
    & > span {
      h2 {
        display: inline-block;
      }
    }
  }

  @media (max-width: 979px) {
    width: 318px;
    height: 126px;
    font-size: 20px;

    & > h1 {
      margin-bottom: 0;
    }
    & > h2 {
      text-align: center;

      & > span {
        display: block;
        h2 {
          display: inline-block;
          color: #000;
        }
      }
    }
  }
`;

const MiddleTextContainer = styled.div`
  height: 210px;
  margin-bottom: 24px;
  color: #000;
  text-align: justify;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  span {
    font-weight: 700;
  }

  @media (max-width: 979px) {
    width: 318px;
    height: 464px;
  }
`;

const Footer = styled.div`
  width: 796px;
  height: 243px;
  margin: 0 auto;
  margin-bottom: 120px;
  background: #f6f6f6;
  color: #000;
  font-family: Cafe24 Ssurround OTF;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  & > h1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 24px;
    padding-left: 40px;
    padding-bottom: 20px;

    & > img {
      margin-right: 8px;
    }
  }
  & > div {
    display: flex;

    & > div {
      width: 325px;
      height: 123px;
      padding: 16px;
      border-radius: 10px;
      border: 1px solid #e9e9e9;
      :nth-of-type(1) {
        margin-left: 40px;
        margin-right: 16px;
      }

      & > h2 {
        margin-bottom: 8px;
        color: #000;
        font-family: Pretendard;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%; /* 22.4px */
      }

      & > p {
        color: #000;
        text-align: justify;
        font-family: Pretendard;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 28.8px */
      }
    }
  }

  @media (max-width: 979px) {
    width: 100%;
    height: 528px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > h1 {
      padding-top: 40px;
      padding-left: 0;
      padding-right: 30px;
      width: 290px;
      & > img {
        margin-bottom: 24px;
      }
    }
    & > div {
      align-items: center;
      flex-direction: column;

      & > div {
        padding: 16px;
        width: 286px;
        height: 153px;
        color: #000;
        text-align: justify;
        font-family: Pretendard;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 28.8px */
        :nth-of-type(1) {
          margin: 0;
          margin-bottom: 16px;
        }
      }
    }
  }
`;
