import styled from "@emotion/styled";

import Coupon from "../components/coupon/index";

export default function CouponLayout() {
  return (
    <>
      <Wrapper>
        <Coupon />
      </Wrapper>

      <Footer>© 2024 eMotiv. All rights reserved.</Footer>
    </>
  );
}

const Wrapper = styled.div`
  background: url(/images/coupon/bg_coupon.png) no-repeat top;
  background-size: 100%;
  background-color: #1c1c1c;
  height: 100%;
  min-height: calc(100vh - 161px);
  display: flex;
  align-items: center;
  flex-direction: column;

  & > h1 {
    color: #fff;
    font-family: Pretendard;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    margin-bottom: 39px;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 160px;

  border-top: 1px solid #6f6f6f;
  background: #272727;

  color: #787878;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
`;
