import styled from "@emotion/styled";

import { ReportData } from "../../types/report";

import { ReactComponent as RECSVG } from "../../assets/rec.svg";

const DATA = {
  low: [
    {
      bold: "처리속도가 느린 어린이는 새로운 정보를 추론하고 통합하는 과제에 많은 시간이 소요되며, 문제를 더 어렵게 느낄 수 있습니다.",
      title:
        "같은 양의 자료를 학습하는 데 또래보다 더 오랜 시간이 걸리며, 일상적인 과제를 수행하는 데 더 많은 노력이 필요하여 정신적인 피로를 쉽게 느끼기도 합니다. 산수 연산 수행 속도가 느리고 구구단을 쉽게 배우지 못하며 제한된 시간 안에 시험을 완수하지 못하고, 손가락이나 계산기를 사용하여 계산하는 경우가 나타날 수 있습니다. 이들은 시간적 압박을 받으면 과제를 완수하는데 더 긴 시간이 걸릴 수 있습니다.",
      bold2: "인지적 발달과 학업성취도에 영향",
      tail: "을 미칠 수 있으므로, 꾸준히 훈련하여 향상하는 것이 중요합니다.",
    },
  ],
  middle: [
    {
      bold: "처리속도가 보통 수준인 어린이는 대체로 인지적 작업을 적절하게 수행하여 작업을 성공적으로 완수할 수 있습니다.",
      title:
        "간단한 과제나 놀이 활동에서 정확하게 반응할 수 있고, 읽기와 산수 연산을 제대로 수행할 수 있으며, 정보를 정확하게 파악하여 결정을 내릴 수 있습니다. 다만, 때때로 자료를 학습하는 데 오랜 시간이 걸리거나 제한된 시간 안에 시험을 완수하지 못할 수 있으나, 대부분의 경우에는 제한된 시간 내에 주어진 활동을 잘 수행할 것으로 보입니다.",
      bold2: "인지적 발달과 학업성취도에 영향",
      tail: "을 미칠 수 있으므로 꾸준히 훈련하는 것이 중요합니다.",
    },
  ],
  high: [
    {
      bold: "처리속도가 빠른 어린이는 인지적 작업을 자동으로 수행하여 작업을 성공적으로 완수할 수 있습니다.",
      title:
        "간단한 과제나 놀이 활동에서 정확하게 반응할 수 있고, 읽기와 산수 연산을 빠르게 수행할 수 있으며, 제한된 시간 내에 주어진 활동을 수행할 수 있습니다. 또한, 정보를 정확하게 파악하여 신속하게 결정을 내리는 편이며, 이를 곧잘 실행에 옮겨서 과제를 성공적으로 완수할 가능성이 있습니다.",
      bold2: "인지적 발달과 학업성취도에 영향",
      tail: "을 미칠 수 있으므로 계속해서 잘 발달할 수 있도록 꾸준히 훈련하는 것이 중요합니다.",
    },
  ],
};

interface Props {
  data?: ReportData;
}

export default function Page9({ data }: Props) {
  const taskType =
    data?.speedScoreEv === "높은"
      ? "high"
      : data?.speedScoreEv === "보통"
      ? "middle"
      : "low";
  return (
    <>
      <Wrapper>
        <Title>처리속도</Title>
        <TopContainer>
          <h1>
            처리속도란? <span>#읽기 #수학성취</span>
          </h1>
          <div>
            처리속도는 주의를 유지하면서 간단한 정보를 빠르고 정확하게 처리하는
            능력으로, 입력되는 정보를 처리하고 적절하게 응답하는 인지적
            의사결정을 포함합니다. 이는 변별 능력, 주의집중력, 정보처리속도,
            감각-운동 협응 능력 등의 상위 인지기능과 상호작용합니다. 추론 및
            새로운 학습, 전반적인 학업 성취도, 일상생활에 영향을 미칠 수 있으며,
            특히 읽기 및 수학 성취와 관련이 있는 것으로 알려져 있습니다.
          </div>
          <RecSvg />
        </TopContainer>
        <MiddleContainer
          color={
            data?.speedScoreEv === "높은"
              ? "#3689DC"
              : data?.speedScoreEv === "보통"
              ? "#63AE18"
              : "#DE5C82"
          }
          bdColor={
            data?.speedScoreEv === "높은"
              ? "#79BCFF"
              : data?.speedScoreEv === "보통"
              ? "#87CA43"
              : "#F88DAD"
          }
        >
          <h1>처리속도 관련 인지 측정 결과</h1>
          <h2>
            우리 아이의 처리속도는{" "}
            <span>
              {data?.memoryScoreEv === "높은"
                ? "빠른 수준"
                : data?.memoryScoreEv === "보통"
                ? "보통 수준"
                : "느린 수준"}
              <h2>으로 추정됩니다.</h2>
            </span>
          </h2>
        </MiddleContainer>
        {DATA[taskType].map((item) => (
          <MiddleTextContainer key={item.title}>
            <h1>
              <span>{item.bold}</span> {item.title}
            </h1>
            <h2>
              처리속도는 이후의 <span>{item.bold2}</span>
              {item.tail}
            </h2>
          </MiddleTextContainer>
        ))}
      </Wrapper>
      <Footer>
        <h1>
          <img src="/images/report/!.png" alt="" />
          재미있게 즐기는 훈련 방법을 통해 처리속도를 키워보세요.
        </h1>
        <div>
          <div>
            <h2>빠르게 퍼즐 맞추기</h2>
            <p>
              퍼즐 맞추기의 목표 시간을 점차 줄여나감으로써 처리속도를 훈련할 수
              있습니다.
            </p>
          </div>
          <div>
            <h2>할리갈리</h2>
            <p>
              같은 과일 다섯 개가 보이면 종을 빠르게 치는 보드게임을 통해
              처리속도를 훈련할 수 있습니다.
            </p>
          </div>
        </div>
      </Footer>
    </>
  );
}

const Wrapper = styled.div`
  position: relative;
  width: 796px;
  margin: 0 auto;
  @media (max-width: 979px) {
    width: 318px;
  }
`;

const Title = styled.div`
  position: absolute;
  top: -23.5px;
  left: 338px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 47px;
  background-color: #131313;
  border-radius: 55px;

  color: #fff;
  font-family: Cafe24 Ssurround OTF;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  z-index: 2;

  @media (max-width: 979px) {
    left: 95.5px;
  }
`;

const TopContainer = styled.div`
  position: relative;
  width: 744px;
  height: 221px;
  padding: 24px;
  padding-top: 90px;
  margin-top: 143px;
  border: 1px solid #e8e8e8;
  background: #fff;

  color: #000;
  text-align: justify;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */

  & > h1 {
    margin-bottom: 8px;
    color: #000;
    font-family: Cafe24 Ssurround OTF;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    & > span {
      color: #0084ff;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }

  & > div {
    :nth-of-type(1) {
      margin-bottom: 16px;
    }

    :nth-of-type(2) {
      padding-left: 24px;
    }

    & > span {
      color: #000;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
    }
  }

  ol {
    list-style-type: decimal;
  }

  @media (max-width: 979px) {
    width: 318px;
    height: 514px;
    padding: 0;
    margin-top: 103px;

    & > h1 {
      margin-top: 38px;
      margin-left: 16px;
      & > span {
        display: block;
        margin-top: 8px;
        margin-bottom: 16px;
      }
    }

    & > div {
      margin-left: 16px;
      :nth-of-type(1) {
        width: 286px;
      }

      :nth-of-type(2) {
        padding: 0 20px;
      }
    }
    li {
      margin-bottom: 8px;
    }
  }
`;

const RecSvg = styled(RECSVG)`
  position: absolute;
  bottom: -1px;
  right: -1px;
`;

const MiddleContainer = styled.div<{ bdColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 792px;
  height: 118px;
  border-radius: 10px;
  border: 3px solid ${(props) => props.bdColor};
  margin-top: 24px;
  margin-bottom: 24px;
  color: #000;
  font-family: Cafe24 Ssurround OTF;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  span {
    color: ${(props) => props.color};
  }

  & > h1 {
    margin-bottom: 8px;
  }

  & > h2 {
    & > span {
      h2 {
        display: inline-block;
      }
    }
  }

  @media (max-width: 979px) {
    width: 318px;
    height: 126px;
    font-size: 20px;

    & > h1 {
      margin-bottom: 0;
    }
    & > h2 {
      text-align: center;

      & > span {
        display: block;
        h2 {
          display: inline-block;
          color: #000;
        }
      }
    }
  }
`;

const MiddleTextContainer = styled.div`
  height: 210px;
  margin-bottom: 24px;
  color: #000;
  text-align: justify;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  span {
    font-weight: 700;
  }

  @media (max-width: 979px) {
    width: 318px;
    height: 464px;
  }
`;

const Footer = styled.div`
  width: 796px;
  height: 243px;
  margin: 0 auto;
  margin-bottom: 120px;
  background: #f6f6f6;
  color: #000;
  font-family: Cafe24 Ssurround OTF;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  & > h1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 24px;
    padding-left: 40px;
    padding-bottom: 20px;

    & > img {
      margin-right: 8px;
    }
  }
  & > div {
    display: flex;

    & > div {
      width: 325px;
      height: 123px;
      padding: 16px;
      border-radius: 10px;
      border: 1px solid #e9e9e9;
      :nth-of-type(1) {
        margin-left: 40px;
        margin-right: 16px;
      }

      & > h2 {
        margin-bottom: 8px;
        color: #000;
        font-family: Pretendard;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%; /* 22.4px */
      }

      & > p {
        color: #000;
        text-align: justify;
        font-family: Pretendard;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 28.8px */
      }
    }
  }

  @media (max-width: 979px) {
    width: 100%;
    height: 528px;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > h1 {
      padding-top: 40px;
      padding-left: 0;
      padding-right: 30px;
      width: 290px;
      & > img {
        margin-bottom: 24px;
      }
    }
    & > div {
      align-items: center;
      flex-direction: column;

      & > div {
        padding: 16px;
        width: 286px;
        height: 153px;
        color: #000;
        text-align: justify;
        font-family: Pretendard;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 28.8px */
        :nth-of-type(1) {
          margin: 0;
          margin-bottom: 16px;
        }
      }
    }
  }
`;
