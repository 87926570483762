import styled from "@emotion/styled";

export default function Footer() {
  return <Wrapper>© 2024 eMotiv. All rights reserved.</Wrapper>;
}

const Wrapper = styled.div`
  height: 160px;
  background: #272727;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #787878;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */

  @media (max-width: 979px) {
    height: 80px;
    font-size: 14px;
  }
`;
