import moment from 'moment';

const dateFilter = (value?: string) => {
  if (value) {
    const formattedDate = moment(value).format('YYYY년 MM월 DD일');
    return formattedDate;
  }
};

export default dateFilter;
