import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export default function index() {
  const handleStore = (name: string) => {
    if (name === "apple") {
      window.open(
        "https://apps.apple.com/kr/app/%EC%A1%B0%EC%9D%B4%EC%BD%94%EA%B7%B8-joycog/id6473777579"
      );
    } else {
      window.open(
        "https://play.google.com/store/apps/details?id=com.eMotiv.rudyadventure&hl=ko-KR"
      );
    }
  };

  return (
    <Wrapper>
      <Images>
        <Img src="/images/JoyCog_Pc01.png" className="pc-image" />
        <Img src="/images/JoyCog_Pc02.png" className="pc-image" />
        <Img src="/images/JoyCog_Pc03.png" className="pc-image" />
        <Img src="/images/JoyCog_Pc04.png" className="pc-image" />
        <Img src="/images/JoyCog_Pc05.png" className="pc-image" />
        <Img src="/images/JoyCog_Mob01.png" className="mobile-image" />
        <Img src="/images/JoyCog_Mob02.png" className="mobile-image" />
        <Img src="/images/JoyCog_Mob03.png" className="mobile-image" />
        <Img src="/images/JoyCog_Mob04.png" className="mobile-image" />
        <Img src="/images/JoyCog_Mob05.png" className="mobile-image" />
      </Images>
      <Footer className="pc-image">
        <Img src="/images/background.png" />
        <Content>
          <FooterContent>
            <img src="/images/webFooterText.png" alt="" />
            <div>
              <Link to="/" onClick={() => handleStore("apple")}>
                <img src="/images/webApple.png" alt="" />
              </Link>
              <Link to="/" onClick={() => handleStore("google")}>
                <img src="/images/webGoogle.png" alt="" />
              </Link>
            </div>
          </FooterContent>
          <FooterImg>
            <img src="/images/webFooterImg.png" alt="" />
          </FooterImg>
        </Content>
      </Footer>
      <MobFooter className="mobile-image">
        <img src="/images/mobBackground.png" alt="" />
        <div>
          <img src="/images/mobFooterText.png" alt="" />
          <div>
            <Link to="/" onClick={() => handleStore("apple")}>
              <img src="/images/mobApple.png" alt="" />
            </Link>
            <Link to="/" onClick={() => handleStore("google")}>
              <img src="/images/mobGoogle.png" alt="" />
            </Link>
          </div>
          <img src="/images/mobFooterImg.png" alt="" />
        </div>
      </MobFooter>
      <Img src="/images/webFooterInfoText.png" className="pc-image" />
      <Img src="/images/mobFooterInfoText.png" className="mobile-image" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 150px;

  @media (max-width: 768px) {
    .pc-image {
      display: none;
    }
  }

  @media (min-width: 769px) {
    .mobile-image {
      display: none;
    }
  }
`;

const Images = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Img = styled.img`
  width: 100%;
`;

const Footer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Content = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const FooterContent = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  margin-right: 4%;

  & > img {
    width: 36%;
    margin-right: 2%;
    margin-bottom: 3%;
  }

  & > div {
    width: 50%;

    & > a {
      width: 100%;

      :nth-of-type(1) {
        margin-left: 22%;
      }

      & > img {
        width: 30%;
      }
    }
  }
`;

const FooterImg = styled.div`
  display: flex;
  align-items: flex-start;
  width: 50%;
  margin-left: 4%;

  & > img {
    width: 70%;
  }
`;

const MobFooter = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  & > div {
    position: absolute;

    & > img {
      :nth-of-type(1) {
        width: 51%;
        margin-left: 7%;
        margin-top: 10%;
      }

      :nth-of-type(2) {
        width: 80%;
        margin-left: 12%;
        margin-top: 3%;
      }
    }

    & > div {
      margin-left: 5%;
      margin-right: 5%;
      margin-top: 3.2%;

      img {
        width: 30%;
      }
    }
  }

  & > img {
  }
`;
