import styled from "@emotion/styled";

import { ReactComponent as SVG1 } from "../../assets/page4_1.svg";
import { ReactComponent as SVG2 } from "../../assets/page4_2.svg";
import { ReactComponent as SVG3 } from "../../assets/page5_1.svg";
import { ReactComponent as SVG4 } from "../../assets/page5_2.svg";
import { ReactComponent as CheckSVG } from "../../assets/check.svg";
import { ReactComponent as DotSVG } from "../../assets/dot.svg";
import { ReportData } from "../../types/report";

interface Props {
  data?: ReportData;
}

export default function Page4({ data }: Props) {
  return (
    <Wrapper>
      <Title>
        <div />
        <h1>세부 지표 평가 결과</h1>
      </Title>
      <Container>
        <div>
          <Svg1 />
          <h1>알맞은 도형 찾기</h1>
        </div>
        <div>
          <p>누락오류</p>
          <p>
            {Number.isInteger(data?.vtotalOmissionError)
              ? data?.vtotalOmissionError
              : data?.vtotalOmissionError.toFixed(2)}
          </p>
          <ColorFiled
            bgColor="#EEE"
            fontW={data?.vtotalOmissionErrorEv === "부족함" ? "700" : "400"}
          >
            {data?.vtotalOmissionErrorEv === "부족함" ? <CheckImg /> : <></>}
            부족함
          </ColorFiled>
          <ColorFiled
            bgColor="#DAEAF8"
            fontW={data?.vtotalOmissionErrorEv === "양호함" ? "700" : "400"}
          >
            {data?.vtotalOmissionErrorEv === "양호함" ? <CheckImg /> : <></>}
            양호함
          </ColorFiled>
          <ColorFiled
            bgColor="#BDE0FF"
            fontW={data?.vtotalOmissionErrorEv === "뛰어남" ? "700" : "400"}
          >
            {data?.vtotalOmissionErrorEv === "뛰어남" ? <CheckImg /> : <></>}
            뛰어남
          </ColorFiled>
        </div>
        <div>
          <p>오경보오류</p>
          <p>
            {Number.isInteger(data?.vtotalCommissionError)
              ? data?.vtotalCommissionError
              : data?.vtotalCommissionError.toFixed(2)}
          </p>
          <ColorFiled
            bgColor="#EEE"
            fontW={data?.vtotalCommissionErrorEv === "부족함" ? "700" : "400"}
          >
            {data?.vtotalCommissionErrorEv === "부족함" ? <CheckImg /> : <></>}
            부족함
          </ColorFiled>
          <ColorFiled
            bgColor="#DAEAF8"
            fontW={data?.vtotalCommissionErrorEv === "양호함" ? "700" : "400"}
          >
            {data?.vtotalCommissionErrorEv === "양호함" ? <CheckImg /> : <></>}
            양호함
          </ColorFiled>
          <ColorFiled
            bgColor="#BDE0FF"
            fontW={data?.vtotalCommissionErrorEv === "뛰어남" ? "700" : "400"}
          >
            {data?.vtotalCommissionErrorEv === "뛰어남" ? <CheckImg /> : <></>}
            뛰어남
          </ColorFiled>
        </div>
        <div>
          <p>정반응시간 평균</p>
          <p>
            {Number.isInteger(data?.vtotalAverageResponseTime)
              ? data?.vtotalAverageResponseTime
              : data?.vtotalAverageResponseTime.toFixed(2)}
          </p>
          <ColorFiled
            bgColor="#EEE"
            fontW={
              data?.vtotalAverageResponseTimeEv === "부족함" ? "700" : "400"
            }
          >
            {data?.vtotalAverageResponseTimeEv === "부족함" ? (
              <CheckImg />
            ) : (
              <></>
            )}
            부족함
          </ColorFiled>
          <ColorFiled
            bgColor="#DAEAF8"
            fontW={
              data?.vtotalAverageResponseTimeEv === "양호함" ? "700" : "400"
            }
          >
            {data?.vtotalAverageResponseTimeEv === "양호함" ? (
              <CheckImg />
            ) : (
              <></>
            )}
            양호함
          </ColorFiled>
          <ColorFiled
            bgColor="#BDE0FF"
            fontW={
              data?.vtotalAverageResponseTimeEv === "뛰어남" ? "700" : "400"
            }
          >
            {data?.vtotalAverageResponseTimeEv === "뛰어남" ? (
              <CheckImg />
            ) : (
              <></>
            )}
            뛰어남
          </ColorFiled>
        </div>
        <div>
          <p>정반응시간 표준편차</p>
          <p>
            {Number.isInteger(data?.vtotalStandardResponseTime)
              ? data?.vtotalStandardResponseTime
              : data?.vtotalStandardResponseTime.toFixed(2)}
          </p>
          <ColorFiled
            bgColor="#EEE"
            fontW={
              data?.vtotalStandardResponseTimeEv === "부족함" ? "700" : "400"
            }
          >
            {data?.vtotalStandardResponseTimeEv === "부족함" ? (
              <CheckImg />
            ) : (
              <></>
            )}
            부족함
          </ColorFiled>
          <ColorFiled
            bgColor="#DAEAF8"
            fontW={
              data?.vtotalStandardResponseTimeEv === "양호함" ? "700" : "400"
            }
          >
            {data?.vtotalStandardResponseTimeEv === "양호함" ? (
              <CheckImg />
            ) : (
              <></>
            )}
            양호함
          </ColorFiled>
          <ColorFiled
            bgColor="#BDE0FF"
            fontW={
              data?.vtotalStandardResponseTimeEv === "뛰어남" ? "700" : "400"
            }
          >
            {data?.vtotalStandardResponseTimeEv === "뛰어남" ? (
              <CheckImg />
            ) : (
              <></>
            )}
            뛰어남
          </ColorFiled>
        </div>
      </Container>
      <Container>
        <div>
          <Svg2 />
          <h1>알맞은 소리 찾기</h1>
        </div>
        <div>
          <p>누락오류</p>
          <p>
            {Number.isInteger(data?.atotalOmissionError)
              ? data?.atotalOmissionError
              : data?.atotalOmissionError.toFixed(2)}
          </p>
          <ColorFiled
            bgColor="#EEE"
            fontW={data?.atotalOmissionErrorEv === "부족함" ? "700" : "400"}
          >
            {data?.atotalOmissionErrorEv === "부족함" ? <CheckImg /> : <></>}
            부족함
          </ColorFiled>
          <ColorFiled
            bgColor="#DAEAF8"
            fontW={data?.atotalOmissionErrorEv === "양호함" ? "700" : "400"}
          >
            {data?.atotalOmissionErrorEv === "양호함" ? <CheckImg /> : <></>}
            양호함
          </ColorFiled>
          <ColorFiled
            bgColor="#BDE0FF"
            fontW={data?.atotalOmissionErrorEv === "뛰어남" ? "700" : "400"}
          >
            {data?.atotalOmissionErrorEv === "뛰어남" ? <CheckImg /> : <></>}
            뛰어남
          </ColorFiled>
        </div>
        <div>
          <p>오경보오류</p>
          <p>
            {Number.isInteger(data?.atotalCommissionError)
              ? data?.atotalCommissionError
              : data?.atotalCommissionError.toFixed(2)}
          </p>
          <ColorFiled
            bgColor="#EEE"
            fontW={data?.atotalCommissionErrorEv === "부족함" ? "700" : "400"}
          >
            {data?.atotalCommissionErrorEv === "부족함" ? <CheckImg /> : <></>}
            부족함
          </ColorFiled>
          <ColorFiled
            bgColor="#DAEAF8"
            fontW={data?.atotalCommissionErrorEv === "양호함" ? "700" : "400"}
          >
            {data?.atotalCommissionErrorEv === "양호함" ? <CheckImg /> : <></>}
            양호함
          </ColorFiled>
          <ColorFiled
            bgColor="#BDE0FF"
            fontW={data?.atotalCommissionErrorEv === "뛰어남" ? "700" : "400"}
          >
            {data?.atotalCommissionErrorEv === "뛰어남" ? <CheckImg /> : <></>}
            뛰어남
          </ColorFiled>
        </div>
        <div>
          <p>정반응시간 평균</p>
          <p>
            {Number.isInteger(data?.atotalAverageResponseTime)
              ? data?.atotalAverageResponseTime
              : data?.atotalAverageResponseTime.toFixed(2)}
          </p>
          <ColorFiled
            bgColor="#EEE"
            fontW={
              data?.atotalAverageResponseTimeEv === "부족함" ? "700" : "400"
            }
          >
            {data?.atotalAverageResponseTimeEv === "부족함" ? (
              <CheckImg />
            ) : (
              <></>
            )}
            부족함
          </ColorFiled>
          <ColorFiled
            bgColor="#DAEAF8"
            fontW={
              data?.atotalAverageResponseTimeEv === "양호함" ? "700" : "400"
            }
          >
            {data?.atotalAverageResponseTimeEv === "양호함" ? (
              <CheckImg />
            ) : (
              <></>
            )}
            양호함
          </ColorFiled>
          <ColorFiled
            bgColor="#BDE0FF"
            fontW={
              data?.atotalAverageResponseTimeEv === "뛰어남" ? "700" : "400"
            }
          >
            {data?.atotalAverageResponseTimeEv === "뛰어남" ? (
              <CheckImg />
            ) : (
              <></>
            )}
            뛰어남
          </ColorFiled>
        </div>
        <div>
          <p>정반응시간 표준편차</p>
          <p>
            {Number.isInteger(data?.atotalStandardResponseTime)
              ? data?.atotalStandardResponseTime
              : data?.atotalStandardResponseTime.toFixed(2)}
          </p>
          <ColorFiled
            bgColor="#EEE"
            fontW={
              data?.atotalStandardResponseTimeEv === "부족함" ? "700" : "400"
            }
          >
            {data?.atotalStandardResponseTimeEv === "부족함" ? (
              <CheckImg />
            ) : (
              <></>
            )}
            부족함
          </ColorFiled>
          <ColorFiled
            bgColor="#DAEAF8"
            fontW={
              data?.atotalStandardResponseTimeEv === "양호함" ? "700" : "400"
            }
          >
            {data?.atotalStandardResponseTimeEv === "양호함" ? (
              <CheckImg />
            ) : (
              <></>
            )}
            양호함
          </ColorFiled>
          <ColorFiled
            bgColor="#BDE0FF"
            fontW={
              data?.atotalStandardResponseTimeEv === "뛰어남" ? "700" : "400"
            }
          >
            {data?.atotalStandardResponseTimeEv === "뛰어남" ? (
              <CheckImg />
            ) : (
              <></>
            )}
            뛰어남
          </ColorFiled>
        </div>
      </Container>
      <Container>
        <div>
          <Svg3 />
          <h1>숫자 순서대로 기억하기</h1>
        </div>
        <div>
          <p>숫자 폭</p>
          <p>
            {Number.isInteger(data?.dfMaxSpan)
              ? data?.dfMaxSpan
              : data?.dfMaxSpan.toFixed(2)}
          </p>
          <ColorFiled
            bgColor="#EEE"
            fontW={data?.dfMaxSpanEv === "부족함" ? "700" : "400"}
          >
            {data?.dfMaxSpanEv === "부족함" ? (
              <CheckImg style={{ marginRight: "5.5px" }} />
            ) : (
              <></>
            )}
            부족함
          </ColorFiled>
          <ColorFiled
            bgColor="#DAEAF8"
            fontW={data?.dfMaxSpanEv === "양호함" ? "700" : "400"}
          >
            {data?.dfMaxSpanEv === "양호함" ? (
              <CheckImg style={{ marginRight: "5.5px" }} />
            ) : (
              <></>
            )}
            양호함
          </ColorFiled>
          <ColorFiled
            bgColor="#BDE0FF"
            fontW={data?.dfMaxSpanEv === "뛰어남" ? "700" : "400"}
          >
            {data?.dfMaxSpanEv === "뛰어남" ? (
              <CheckImg style={{ marginRight: "5.5px" }} />
            ) : (
              <></>
            )}
            뛰어남
          </ColorFiled>
        </div>
      </Container>
      <Container>
        <div>
          <Svg4 />
          <h1>숫자 거꾸로 기억하기</h1>
        </div>
        <div>
          <p>숫자 폭</p>
          <p>
            {Number.isInteger(data?.dbMaxSpan)
              ? data?.dbMaxSpan
              : data?.dbMaxSpan.toFixed(2)}
          </p>
          <ColorFiled
            bgColor="#EEE"
            fontW={data?.dbMaxSpanEv === "부족함" ? "700" : "400"}
          >
            {data?.dbMaxSpanEv === "부족함" ? (
              <CheckImg style={{ marginRight: "5.5px" }} />
            ) : (
              <></>
            )}
            부족함
          </ColorFiled>
          <ColorFiled
            bgColor="#DAEAF8"
            fontW={data?.dbMaxSpanEv === "양호함" ? "700" : "400"}
          >
            {data?.dbMaxSpanEv === "양호함" ? (
              <CheckImg style={{ marginRight: "5.5px" }} />
            ) : (
              <></>
            )}
            양호함
          </ColorFiled>
          <ColorFiled
            bgColor="#BDE0FF"
            fontW={data?.dbMaxSpanEv === "뛰어남" ? "700" : "400"}
          >
            {data?.dbMaxSpanEv === "뛰어남" ? (
              <CheckImg style={{ marginRight: "5.5px" }} />
            ) : (
              <></>
            )}
            뛰어남
          </ColorFiled>
        </div>
      </Container>
      <TextContainer>
        <div>
          <DotSVG
            style={{
              marginRight: "16px",
              minWidth: "6px",
              minHeight: "6px",
              marginTop: "8px",
            }}
          />
          <h1>
            누락오류
            <span>
              (Omission error)란 목표 자극에 반응하지 않는 오류 양상을 나타내며,
              부주의와 관련됩니다.
            </span>
          </h1>
        </div>
        <div>
          <DotSVG
            style={{
              marginRight: "16px",
              minWidth: "6px",
              minHeight: "6px",
              marginTop: "8px",
            }}
          />
          <h1>
            오경보오류
            <span>
              (Commission error)는 목표가 아닌 자극에 반응한 오류 양상을
              나타내며, 인지적 충동성, 주의 억제 능력과 관련됩니다.
            </span>
          </h1>
        </div>
        <div>
          <DotSVG
            style={{
              marginRight: "16px",
              minWidth: "6px",
              minHeight: "6px",
              marginTop: "8px",
            }}
          />
          <h1>
            정반응시간 평균
            <span>
              (Average of response time)이란 목표 자극에 반응하는 데 소요된
              시간을 나타내며, 정신처리속도와 관련됩니다.
            </span>
          </h1>
        </div>

        <div>
          <DotSVG
            style={{
              marginRight: "16px",
              minWidth: "6px",
              minHeight: "6px",
              marginTop: "8px",
            }}
          />
          <h1>
            정반응시간 표준편차
            <span>
              (Standard deviation of response time)는 목표자극에 반응하는 시간이
              얼마나 일정하게 유지되는지를 나타내는 지표입니다. 주의지속능력,
              비일관적인 반응 속도와 관련됩니다.
            </span>
          </h1>
        </div>

        <div>
          <DotSVG
            style={{
              marginRight: "16px",
              minWidth: "6px",
              minHeight: "6px",
              marginTop: "8px",
            }}
          />
          <h1>
            숫자 폭
            <span>
              (Digit span)이란 숫자 기억 용량을 나타내는 지표입니다. 일시적으로
              정보를 저장하거나 조작할 수 있는 작업 기억의 크기와 관련됩니다.
              숫자 순서대로 기억하기 과제의 숫자폭은 작업기억력, 숫자 거꾸로
              기억하기 과제의 숫자폭은 응용작업기억력과 관련됩니다.
            </span>
          </h1>
        </div>
      </TextContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 792px;
  margin: 0 auto;
  margin-bottom: 96px;

  @media (max-width: 979px) {
    width: 318px;
  }
`;

const Title = styled.div`
  display: flex;
  padding-top: 40px;
  margin-bottom: 40px;

  & > div {
    width: 4px;
    height: 31px;
    border-radius: 60px;
    background: #ffe9a3;
    margin-right: 8px;
  }

  & > h1 {
    color: #000;
    font-family: Cafe24 Ssurround OTF;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 8px;
  }

  @media (max-width: 979px) {
    padding-top: 0;
    margin-bottom: 61px;
  }
`;

const Container = styled.div`
  border-radius: 10px;
  border: 1px solid #d6d6d6;
  margin-bottom: 16px;
  background: #fff;

  & > div {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    margin-left: 31px;
    margin-right: 23px;

    :nth-of-type(1) {
      margin-left: 24px;
      margin-top: 16px;
      margin-bottom: 30px;
    }

    :nth-last-of-type(1) {
      margin-bottom: 24px;
    }

    & > p {
      :nth-of-type(1) {
        width: 170px;
        color: #000;
        font-family: Pretendard;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      :nth-of-type(2) {
        color: #000;
        text-align: center;
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 33px;
        margin-left: 16px;
        margin-right: 63px;
      }
    }
    & > h1 {
      color: #000;
      font-family: Cafe24 Ssurround OTF;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  @media (max-width: 979px) {
    & > div {
      margin-left: 16px;
      margin-right: 14px;

      :nth-of-type(1) {
        margin-left: 8px;
        margin-top: 16px;
        margin-bottom: 16px;
      }

      :nth-last-of-type(1) {
        margin-bottom: 32px;
      }

      & > p {
        :nth-of-type(1) {
          min-width: 100px;
          font-size: 12px;
        }

        :nth-of-type(2) {
          width: 21px;
          font-size: 10px;
          margin-left: 4px;
          margin-right: 16px;
        }
      }

      & > h1 {
        font-size: 18px;
      }
    }
  }
`;

const ColorFiled = styled.div<{ bgColor: string; fontW: string }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 136px;
  height: 19px;
  padding: 8px;
  border-right: 1px solid #fff;
  background: #eee;
  background-color: ${(props) => props.bgColor};
  color: #000;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: ${(props) => props.fontW};
  line-height: normal;

  @media (max-width: 979px) {
    width: 49px;
    height: 10px;
    padding: 4px 8px;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 8px;
    font-size: 8px;
  }
`;

const CheckImg = styled(CheckSVG)`
  position: absolute;
  top: 9px;
  left: 16px;
  width: 16px;
  height: 16px;
  @media (max-width: 979px) {
    width: 10px;
    height: 10px;
    top: 4px;
    left: 4px;
  }
`;

const TextContainer = styled.div`
  color: #000;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 17.6px */
  & > div {
    display: flex;
    :nth-of-type(2) {
      margin: 0;
    }
    :nth-of-type(3) {
      margin: 0;
    }
    :nth-of-type(4) {
      margin: 0;
    }

    & > h1 {
      margin-bottom: 24px;
      & > span {
        font-weight: 400;
      }
    }

    & > p {
      margin-left: 15px;
      font-weight: 400;
    }
  }
`;

const Svg1 = styled(SVG1)`
  margin-right: 16px;
  width: 64px;
  height: 55px;

  @media (max-width: 979px) {
    width: 50px;
    height: 40px;
    margin-right: 14px;
  }
`;

const Svg2 = styled(SVG2)`
  margin-right: 16px;
  width: 68px;
  height: 55px;

  @media (max-width: 979px) {
    width: 50px;
    height: 40px;
    margin-right: 14px;
  }
`;

const Svg3 = styled(SVG3)`
  margin-right: 16px;
  width: 68px;
  height: 55px;

  @media (max-width: 979px) {
    width: 50px;
    height: 40px;
    margin-right: 14px;
  }
`;

const Svg4 = styled(SVG4)`
  margin-right: 16px;
  width: 68px;
  height: 55px;

  @media (max-width: 979px) {
    width: 50px;
    height: 40px;
    margin-right: 14px;
  }
`;
