import api from "./base";
import { AxiosResponse } from "axios";
import { ReportResultData, ReportResultParams } from "../types/report";

export const postReportResult = (
  params: ReportResultParams,
  token: string | null
): Promise<AxiosResponse<ReportResultData>> =>
  api.post("/api/v1/cognitive/report", params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// export const postReportResult = (
//   params: ReportResultParams,
//   customHeaders?: Record<string, string>
// ): Promise<AxiosResponse<ReportResultData>> => {
//   const headers = {
//     ...customHeaders, // 사용자 지정 헤더를 덮어쓰기
//   };

//   return api.post("/api/v1/cognitive/report", params, {
//     headers,
//   });
// };
