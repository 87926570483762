import { useEffect, useState } from "react";
import Store from "./Store";
import Login from "./Login";
import Coupon from "./Coupon";

export default function Index() {
  const [uid, setUid] = useState<string | null>(null);

  useEffect(() => {
    const uidCookie = document.cookie
      .split(";")
      .map((cookie) => cookie.trim())
      .find((cookie) => cookie.startsWith("uid="));

    const userTokenCookie = document.cookie
      .split(";")
      .map((cookie) => cookie.trim())
      .find((cookie) => cookie.startsWith("accessToken="));

    if (uidCookie && userTokenCookie) {
      const uuid = uidCookie.split("=")[1];
      setUid(uuid);
    } else {
      setUid("");
    }
  }, []);

  return (
    <>
      {!uid ? (
        <>
          <Login />
        </>
      ) : (
        <Coupon uid={uid} setUid={setUid} />
      )}
      <Store />
    </>
  );
}
