import { ReactComponent as TailSvg } from "../../assets/coupon/img_tail.svg";
import styled from "@emotion/styled";

export default function Registration() {
  return (
    <>
      <Wrapper>
        <Img />
        <h1>쿠폰 등록이 완료되었습니다.</h1>
        <h2>조이 코그 앱에서 확인해주세요.</h2>
        <Tail />
      </Wrapper>
      <RudyImg />
    </>
  );
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 384px;
  height: 264px;
  margin-top: 319px;
  margin-bottom: 134px;
  background-color: #fff;
  border-radius: 16px;
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;

  & > h1 {
    margin-top: 24px;

    @media (max-width: 679px) {
      margin-top: 40px;
    }
  }

  & > h2 {
    font-weight: 400;
  }

  @media (max-width: 679px) {
    width: 318px;
    height: 264px;
    margin-top: 216px;
    margin-bottom: 100px;
  }
`;

const Img = styled.div`
  width: 175px;
  height: 50px;

  margin-top: 76px;

  background: url(/images/coupon/logo.png) no-repeat;
  background-size: cover;

  @media (max-width: 679px) {
    width: 140px;
    height: 40px;
    margin-top: 54px;
  }
`;

const RudyImg = styled.div`
  background: url(/images/coupon/img_rudy.png) no-repeat;
  background-size: cover;
  width: 312px;
  height: 242px;
  top: 172px;
  position: absolute;

  @media (max-width: 679px) {
    width: 250px;
    height: 194px;
    top: 88px;
  }
`;

const Tail = styled(TailSvg)`
  position: absolute;
  bottom: -20px;
`;
